(function () {
  'use strict';

  /* @ngdoc object
   * @name neo.security
   * @description
   *
   */
  angular
  .module('neo.security', []);
}());
